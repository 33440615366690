<template>
  <section class="section is-paddingless">
    Affiliate Data Sent
  </section>
</template>

<script>
/*eslint no-console: 2*/

import { i18n } from "../plugins/i18n";


export default {
	name: "Affiliate",
	components: {
		
	},
	data() {
		return {
			
		};
	},
	async mounted() {
		let data = {
			id: localStorage.getItem('affiliate_db_id'),
			airdrop_referred_by: localStorage.getItem('airdrop_referred_by'),
			airdrop_custom_invite: localStorage.getItem('airdrop_custom_invite'),
			affiliate_id: localStorage.getItem('affiliate_id'),
			at_gd: localStorage.getItem('at_gd'),
			affiliate_campaign: localStorage.getItem('affiliate_campaign'),
			affiliate_referrer: localStorage.getItem('affiliate_referrer'),
			affiliate_utm_source: localStorage.getItem('affiliate_utm_source'),
			affiliate_utm_medium: localStorage.getItem('affiliate_utm_medium'),
			affiliate_utm_campaign: localStorage.getItem('affiliate_utm_campaign'),
			affiliate_path: localStorage.getItem('affiliate_path'),
		}

		window.postMessage(JSON.stringify(data))

		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(JSON.stringify(data))
		}

	},
	methods: {
		
	},
	head() {
		return {
			title: { inner: "Mobile Affiliate Data" },
			meta: [{ hid: "robots", name: "robots", content: "noindex" }],
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
